import React from "react"
import ConditionalLayout from "../components/ConditionalLayout"
import { Box } from "@singita/components"
import FormWrapper from "../modules/Forms/FormWrapper"
import { Heading, Text } from "@singita/components"
import LinkWrapper from "../components/Link"
import HelmetWrapper from "gatsby-singita-theme/src/components/Helmet"
import { useSeo } from "gatsby-singita-theme/src/hooks"
import { graphql } from 'gatsby'
import { truncateStr } from "../common/utils"

const DATA_MAP = {
  title: ({ headline, location }) => {
    return location ? `${headline} | ${location}` : headline
  },
  description: ({ description }) => {
    return description ? truncateStr(description.description) : null
  },
  noIndex: () =>  {return true},
  noFollow: () =>  {return true} 
}

const CareerTemplate = props => {
  const {
    name,
    headline,
    description,
    details,
    location,
    ...rest
  } = props.data.contentfulCareer

  const { contentfulForm = {} } = props.data
  const { styles = {}, formId, successMessage } = contentfulForm
  const seoProps = useSeo(props.data.contentfulCareer, DATA_MAP)

  return (
    <>
      <HelmetWrapper {...seoProps} />
      <ConditionalLayout
        modalSize="medium"
        bg="brandBeige"
        modalTitle={headline}
        modalSubtitle={location}
      >
        {isModal => (
          <Box
            maxWidth={isModal ? "none" : "700px"}
            mx="auto"
            pt={isModal ? [0] : [10]}
            pb={isModal ? [0] : [14]}
          >
            {!isModal ? (
              <>
                <Box mb={[1]}>
                  <Heading size="h1">{headline}</Heading>
                </Box>
                <Box mb={[3]}>
                  <Text color="brandBrown" size="small">
                    {location}
                  </Text>
                </Box>
              </>
            ) : null}
            {description && (
              <Box mb={[3]}>
                <Text sx={{ whiteSpace: "pre-line" }}>
                  {description.description}
                </Text>
              </Box>
            )}
            {details ? (
              <Box mb={[8]}>
                <LinkWrapper
                  variant="text"
                  colorScheme="brandBrown"
                  isDownload={true}
                  to={details.file.url}
                  label="View Details"
                  external={true}
                />
              </Box>
            ) : null}
            <FormWrapper
              formId={formId}
              styles={styles}
              successMessage={successMessage}
              renderTitle={false}
              defaultValues={{ position: headline }}
              mergeFields={{ position: { disabled: true } }}
              {...rest}
            />
          </Box>
        )}
      </ConditionalLayout>
    </>
  )
}

export default CareerTemplate

export const careerQuery = graphql`
  query CareerQuery($slug: String!) {
    contentfulForm(contentful_id: { eq: "xmdgrBc24NQBzqA2sYHZ6" }) {
      ...FormFields
    }
    contentfulCareer(slug: { eq: $slug }) {
      id
      name
      slug
      headline
      recipientEmail
      email
      description {
        description
      }
      details {
        file {
          url
        }
      }
      location
    }
  }
`
